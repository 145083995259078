.custom-report-wrapper {
  overflow: auto;
  font-size: 14px;
}

.custom-filter-wrapper {
  background: #DADFE4;
  margin: 10px 0px;
  padding-top: 1rem;
  font-family: sans-serif;
  width: fit-content;
  min-width: 100%;
  min-height: 100px;

  .MuiToolbar-root {
    padding: 0 !important;
    margin: 0 !important;

    .MuiFormControl-root {
      width: 10%;
      margin-left: 1.5rem;

      .select-wrapper {
        // margin-top: 7px;

        .MuiSelect-icon {
          display: none;
        }
      }

    }
  }
}

.selected-values {
  margin-right: 0.25rem;
  width: 75%;
}

.filter-toolbar {
  margin-bottom: 1rem !important;
}

// amount filters
.amount-filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 25%;

  p {
    margin: 0 !important;
  }

  .offer-amount-wrapper,
  .loan-amount-wrapper {
    padding-top: 1rem;
    width: 25% !important;
  }
}

// action buttons
.action-wrapper,
.action-wrapper {
  font-weight: bold !important;
  margin-left: 1.5rem !important;
  background-color: #cfd8dc !important;
}

// date range
.date-range-wrapper {}

.title {
  color: #000 !important;
  font-size: 1rem;
  font-weight: bold;
  padding-top: 1.5rem;
  padding-left: 2rem;
}

.img-wrapper {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;
}

.per-page-wrapper {
  width: 5% !important;
  margin-right: 2.5rem !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// Login
.base-container {
	display: flex;
	margin: auto;
	max-width: 400px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto auto;
	padding: 5rem 4rem;
	background: #fdfefe;
	box-shadow: 12px 8px 44px -12px rgba(27, 58, 146, 0.16);
	border-radius: 20px;

	button {
		background: olive;
		border-radius: 4px;
	}

	.header {
		padding: 1rem;
	}

	.content {
		margin: 1rem;
		width: 100%;
	}

	.cta-button {
		margin: 1rem;
	}

	.user-input {
		padding: 0.5rem;
		width: 100%;
		border: 1px solid #f2f2f2;
		box-sizing: border-box;
		border-radius: 4px;
	}

	.btn {
		font-weight: 900;
		background-color: #ff9300;
		color: white;
	}

	.btn:hover {
		background-color: #ffa931;
	}

	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
}

.navBack {
	background-color: #2f3c54 !important;
}

.red {
	color: red;
}

.navSize {
	font-size: 18px;
}

.sideBar {
	margin-top: 59px;
}

.userHeader {
	display: flex;
	justify-content: space-between;
}

.marginAuto {
	margin: auto;
}

.black-color {
	color: black;
}

.view-row {
	font-size: 17px;
}
.view-btn {
	margin: auto;
}

.imageFile {
	max-height: 150px;
	max-width: 150px;
}

.smallImageFile {
	max-height: 100px;
	max-width: 100px;
}

.white {
	color: white;
	text-decoration: none;
}

.card-desc {
	max-height: 40px;
	overflow: hidden;
}

.message-chat {
	width: 100%;
	display: flex !important;
	justify-content: space-between;
}

.decisionKey {
	width: 90px;
}

.red {
	color: red;
}

[data-letters]:before {
	content: attr(data-letters);
	display: inline-block;
	font-size: 1em;
	width: 2.5em;
	height: 2.5em;
	line-height: 2.5em;
	text-align: center;
	border-radius: 50%;
	background: rgb(160, 221, 201);
	vertical-align: middle;
	color: white;
}

.width-100 {
	.MuiFormControlLabel-label {
		width: 100%;
	}
	width: 100%;
}

.grey {
	background: #8a8a9782;
}

.userHeader h4 {
	margin-left: 0px;
}

.reports-side-menu {
	width: 12%;
}
.admin-wrapper-sidebar {
	display: flex;
	padding: 15px 15px 0;
}
.reports-side-menu .MuiTypography-root {
	margin: 0;
	padding: 12px 15px;
}
.reports-side-menu .MuiPaper-root {
	padding: 0 !important;
}
.reports-side-menu .active-tab {
	background-color: #2f3c54;
	color: white;
}
.sidebar-content-container {
	padding-left: 15px;
}
.sidebar-content-container .MuiPaper-root {
	margin-top: 0 !important;
}

.sidebar-content-container .MuiAppBar-root.MuiAppBar-positionStatic {
	background-color: white !important;
	box-shadow: 0 0 15px #d9d9d9 !important;
}
.admin-card {
	background: white;
	padding: 10px 15px;
	margin-bottom: 20px;
	box-shadow: 0 0 15px #d9d9d9;
}
.custom-form-table .MuiTableCell-head,
.custom-app-tabs .MuiCollapse-wrapper .MuiTableCell-root {
	padding-left: 0;
}
.admin-card .recharts-default-legend {
	top: 30px;
	position: relative;
}
.admin-card .recharts-wrapper {
	height: auto !important;
	width: 100% !important;
}
.admin-card .recharts-surface {
	width: 100%;
}
.admin-card .recharts-default-legend .recharts-legend-item {
	margin: 0 !important;
	padding: 0 10px;
}
.filter-row > header {
	padding-top: 20px !important;
}

.MuiTable-root td b {
	font-weight: 500;
}
.bold-row {
	background-color: #e8e8e8;
}
.bold-row b {
	font-weight: 600 !important;
}
.custom-app-tabs .react-tabs__tab-list > li,
.custom-app-tabs .nav-item > .nav-link {
	color: #505050;
	margin-bottom: 10px;
}
.custom-app-tabs .react-tabs__tab--selected,
.custom-app-tabs .nav-item > .nav-link.active {
	background: #2f3c54;
	border-color: #2f3c54;
	color: white !important;
	font-weight: 500;
	border-radius: 0;
}
.custom-app-tabs b {
	font-weight: 500;
}
.custom-app-tabs .MuiAccordion-root {
	margin-bottom: 5px;
	box-shadow: none;
}
.tab-btn-list button,
.tab-btn-list input[type="submit"] {
	background: #2f3c54;
	border-color: #2f3c54;
	color: white;
	text-shadow: none;
	font-weight: 500;
}

.active-page {
	background: #2f3c54;
	border-color: #2f3c54 !important;
	color: white;
}

.card-body {
	position: relative;
}

.tableLoader {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	background: rgba(255, 255, 255, 0.7);
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 25px;
}

.tableLoader.tableLoaderLarge {
	position: fixed;
	z-index: 9999999999999;
}

.custom-app-tabs li {
	position: relative;
}

.custom-app-tabs li .custom-badge-count {
	position: absolute;
	top: -4px;
	right: -5px;
	height: 20px;
	width: 20px;
	background: #ce93d8;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-size: 13px;
	font-weight: 600;
	color: #333;
}

.custom-app-tabs .react-tabs__tab--selected .custom-badge-count {
	display: none;
}

.unread-ticket-item {
	background-color: #dbedff;
}

.unreadIndicator {
	position: absolute;
	height: 12px;
	width: 12px;
	background: #39f;
	border-radius: 50%;
	top: 50%;
	right: 12px;
	transform: translateY(-50%);
}

.unread-ticket-item.ticket-read-cls {
	background: transparent;
}

.unread-ticket-item.ticket-read-cls .unreadIndicator {
	display: none;
}

.deleteApplicationBtn {
	color: #e55353;
	position: absolute;
	right: 10px;
	display: none;
	cursor: pointer;
	font-weight: bold;
}

.justified-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.filter-btn {
	position: relative;
}

.filter-btn .badge {
	position: absolute;
	top: -7px;
	right: -7px;
	font-size: 13px;
}

table tr:hover .deleteApplicationBtn {
	display: inline-block !important;
}

.custom-datetime-picker {
	width: 100%;
}

.custom-datetime-picker .react-datetime-picker__wrapper {
	border-color: #d8dbe0;
	min-height: 35px;
	border-radius: 5px;
	padding-left: 10px;
}

.backAppBtn {
	position: absolute;
	padding: 0;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	border-radius: 50%;
	justify-content: center;
	top: 19px;
	left: -4px;
	background: #2f3c54;
}

.info-container-product:hover .tool-tip-product {
	display: block;
}

.tool-tip-product {
	background-color: #ffffff;
	margin-top: 10px;
	width: 150px;
	font-size: 16px;
	display: none;
}
